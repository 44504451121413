import React from 'react';
import Link from "next/link";

const Footer = ({t}) => {

    const footer = t('common:footer', {}, {returnObjects: true});

    const MainItem = ({item, i}) => {
        return <ul className='footer_main_item'>
            {item.list.map(item => {
                return <li className='footer_main_item_list' key={item.link}>
                    <Link
                        className='list_item'
                        prefetch={false}
                        href={item.url}
                    >
                        {item.link}
                    </Link>
                </li>
            })}
            {i === 2 && <li className='footer_main_item_list' key={item.link}>
                <Link
                    className='list_item'
                    prefetch={false}
                    href={`mailto:support@goodeggtoken.com`}
                >
                    Support@goodeggtoken.com
                </Link>
            </li>}
        </ul>
    }

    return (
        <footer className='footer'>
            <div className="container">
                <section className='footer_top'>
                    <Link
                        className='nav_logo'
                        prefetch={false}
                        href={'/'}
                    >
                        <img src={'/img/footer_logo.png'} alt={'Buy $GOODEGG'}/>
                    </Link>
                </section>
                <nav className='footer_main'>
                    {footer.main.map((item, i) => {
                        return <MainItem
                            key={item.key}
                            item={item}
                            i={i}
                        />
                    })}
                </nav>
                <section className='footer_bottom'>
                    <p className='footer_bottom_copyright'>
                        © GoodEgg {new Date().getFullYear()}. {footer.bottom.copy}
                    </p>
                    <p className={'footer_bottom_disclaimer'}>{footer.bottom.disclaimer}</p>
                    <ul className='footer_bottom_social'>
                        {footer.bottom.social.map(item => {
                            return <li key={item.link} className='footer_bottom_social_item'>
                                <Link
                                    target={'_blank'}
                                    className='social_item'
                                    href={item.url}
                                    aria-label={item.link}
                                >
                                    {item.link === 'telegram' ?
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.8959 7.13917L4.16184 9.91374C2.97687 10.3762 3.67051 10.8097 3.67051 10.8097C3.67051 10.8097 4.68207 11.1565 5.54912 11.4166C6.41618 11.6767 6.8786 11.3877 6.8786 11.3877L10.9537 8.64206C12.3988 7.6594 12.052 8.46865 11.7052 8.81547C10.9537 9.56692 9.71097 10.7519 8.67051 11.7056C8.20808 12.1103 8.4393 12.4571 8.64161 12.6305C9.22975 13.1282 10.6146 14.033 11.2442 14.4444C11.419 14.5586 11.5356 14.6348 11.5607 14.6536C11.7052 14.7692 12.5144 15.2895 13.0058 15.1739C13.4971 15.0582 13.5549 14.3935 13.5549 14.3935L14.2774 9.85593C14.3417 9.43054 14.4059 9.01407 14.4657 8.6257C14.6215 7.61559 14.7479 6.79559 14.7688 6.50333C14.8555 5.52067 13.815 5.9253 13.815 5.9253C13.815 5.9253 11.5607 6.85015 10.8959 7.13917Z"
                                                  fill="white"/>
                                        </svg> : item.link === 'x' ?
                                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M19.422 1.20733C19.1383 2.15135 18.5577 2.86604 17.7469 3.41539C18.1349 3.39405 18.5068 3.30605 18.8788 3.20738C19.2534 3.10871 19.612 2.97004 20 2.81537C19.8662 3.04738 19.719 3.22872 19.5718 3.41005C19.133 3.9514 18.6353 4.43141 18.0706 4.84476C17.993 4.90076 17.9663 4.9621 17.969 5.05543C18.0305 8.18618 17.011 10.9356 14.9719 13.3037C13.8025 14.661 12.3629 15.6637 10.6877 16.3171C9.08215 16.9438 7.41236 17.1864 5.69173 17.1064C4.05673 17.0318 2.50736 16.6237 1.04094 15.9011C0.682366 15.7251 0.331817 15.5304 0 15.2984C1.07038 15.4024 2.12202 15.3197 3.15761 15.0344C4.19588 14.7464 5.13781 14.2744 6.02355 13.597C5.84426 13.5783 5.69976 13.5677 5.55793 13.5463C4.35644 13.365 3.40915 12.781 2.71341 11.789C2.51539 11.5063 2.36018 11.2023 2.24512 10.8796C2.19695 10.7436 2.20498 10.7329 2.34948 10.7569C2.86326 10.8449 3.37169 10.8183 3.87744 10.7063C3.9042 10.7009 3.92829 10.6956 3.95504 10.6876C3.9604 10.6849 3.96575 10.6743 3.97645 10.6583C3.83998 10.6183 3.70351 10.5809 3.56971 10.5382C1.94006 10.0102 0.767996 8.43419 0.75194 6.75148C0.749264 6.59414 0.749264 6.59414 0.893765 6.66614C1.37276 6.90882 1.87851 7.05015 2.4137 7.09549C2.44849 7.09816 2.48595 7.09815 2.52074 7.09815C2.52877 7.09815 2.53679 7.09015 2.54482 7.08482C2.54482 7.03415 2.49666 7.02615 2.46722 7.00482C0.816163 5.79679 0.305057 3.56206 1.27375 1.76201C1.34332 1.63134 1.346 1.63134 1.43698 1.74067C2.58229 3.09805 3.96307 4.14874 5.58202 4.88743C6.67648 5.38611 7.82446 5.70612 9.02061 5.84479C9.24539 5.87145 9.47284 5.88479 9.7003 5.90879C9.81001 5.92212 9.82606 5.88745 9.80466 5.78345C9.47552 4.11674 10.198 2.43136 11.627 1.52467C13.2539 0.495307 15.3572 0.711313 16.7353 2.06068C16.837 2.15935 16.9253 2.16202 17.0431 2.13535C17.8485 1.94868 18.6112 1.66067 19.3283 1.25533C19.3605 1.23933 19.3899 1.22333 19.422 1.20733Z"
                                                    fill="white"/>
                                            </svg> : <svg width="17" height="20" viewBox="0 0 17 20" fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.6667 8.12005C14.6577 8.18831 13.1071 7.44714 11.8783 5.81852C11.8686 6.07208 11.8491 6.23787 11.8491 6.40366C11.8491 8.87097 11.8588 11.3383 11.8491 13.8154C11.8296 17.1604 9.1282 19.7935 5.78318 19.7447C2.5942 19.6862 -0.019396 16.9946 0.000108463 13.7861C0.0196129 10.5679 2.60395 7.94451 5.80269 7.9055C5.94897 7.9055 6.0855 7.925 6.26104 7.93476C6.26104 8.88072 6.26104 9.79743 6.26104 10.7239C5.90021 10.7727 5.52962 10.7922 5.16879 10.8702C3.68645 11.231 2.72098 12.6646 2.90627 14.2249C3.08181 15.678 4.37886 16.8288 5.89046 16.858C7.4118 16.8873 8.86489 15.7755 9.02092 14.3322C9.13795 13.2887 9.09894 12.2257 9.10869 11.1725C9.11844 7.71045 9.11844 4.24841 9.1282 0.796125C9.1282 0.620585 9.1282 0.445045 9.1282 0.25C10.0644 0.25 10.9519 0.25 11.8588 0.25C12.3659 2.91236 13.9653 4.48247 16.6667 4.91157C16.6667 5.97456 16.6667 7.01805 16.6667 8.12005Z"
                                                    fill="white"/>
                                            </svg>
                                    }
                                </Link>
                            </li>
                        })}
                    </ul>
                </section>
            </div>
        </footer>
    );
}

export default Footer;