import React from 'react';

const Loader = ({isLoading, loaded}) => {
    return (
        <div className={`page_loader ${isLoading ? 'loading' : ''} ${loaded ? 'loaded' : ''}`}>
            <div className="page_loader_content">
                <img src={'/img/small_logo.svg'} alt={'GOODEGG Logo'}/>
            </div>
        </div>
    );
};

export default Loader;