import React, {useEffect, useState} from 'react';
import Link from "next/link";
import ChangeLanguage from "./changeLanguage";

let scrollTop;
let prevDepth;

const MenuItem = ({item, i, toggleMenu}) => {
    return <li className={`main_nav_item`}
               data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
               data-aos-delay={100 * (i+1)}
    >
        <Link
            onClick={() => toggleMenu()}
            prefetch={false}
            scroll={false}
            className='nav_left_list_inner_link'
            href={item.url === 'whitepaper' ? process.env.NEXT_PUBLIC_WHITEPAPER_URL : item.url}
            target={item.target}
        >
            {item.title}
        </Link>

    </li>
}

const Header = ({t}) => {

    const menu = t('common:menu', {}, {returnObjects: true})
    const locales = t('common:locales', {}, {returnObjects: true})
    const [isMenuVisible, setIsMenuVisible] = useState(false)

    const [scrolled, setScrolled] = useState(false);
    const [blur, setBlur] = useState(false);

    const handleScroll = () => {

        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop < 10) {
            setBlur(false)
        }

        if (scrollTop >= 11) {
            if (prevDepth < scrollTop) {
                setScrolled(true)

            } else {
                setScrolled(false)
                setBlur(true)
            }
        }
        prevDepth = window.pageYOffset || document.documentElement.scrollTop;
    }

    useEffect(() => {
        if (window.pageYOffset || document.documentElement.scrollTop > 11) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
        setTimeout(() => {
            window.addEventListener('scroll', handleScroll)
        }, 100)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    }

    useEffect(() => {
        isMenuVisible ? document.body.classList.add('is_menu_visible') : document.body.classList.remove('is_menu_visible')
    }, [isMenuVisible])

    return (
        <header className={`header ${scrolled ? ' scrolled' : ''} ${blur ? 'blur' : ''}`}>
            <div className="container">
                <div className='header_wrapper'>
                    <Link
                        className='nav_logo'
                        prefetch={false}
                        href={'/'}
                    >
                        <img src={'/img/logo.png'} alt={'Buy $GOODEGG'}/>
                    </Link>
                    <nav className='nav_list_main nav_list'>
                        <ul className='nav_list_main_items'>
                            {menu.list.map((item, i) => {
                                return <MenuItem
                                    toggleMenu={toggleMenu}
                                    i={i}
                                    key={item.title}
                                    item={item}
                                />
                            })}
                        </ul>
                    </nav>
                    <nav className='nav_list_add nav_list'>
                        <ul className='nav_list_add_items'>
                            <li className='lang phone_hidden nav_item'
                                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={100 * 7}
                            >
                                <ChangeLanguage
                                    locales={locales}
                                />
                            </li>
                            <li
                                className='nav_item'
                                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={100 * 9}
                            >
                                <Link
                                    className='nav_buy_button'
                                    prefetch={false}
                                    href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}
                                >
                                    <button>
                                        {menu.button}
                                        <span className={'gegg_hidden'}>$GEGG</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24"
                                             viewBox="0 0 26 24" fill="none">
                                            <path opacity="0.8"
                                                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                                  fill="#BF8600"/>
                                            <path
                                                d="M13.715 24C20.3424 24 25.715 18.6274 25.715 12C25.715 5.37258 20.3424 0 13.715 0C7.08755 0 1.71497 5.37258 1.71497 12C1.71497 18.6274 7.08755 24 13.715 24Z"
                                                fill="#EAA500"/>
                                            <path
                                                d="M13.7183 20.5767C18.4555 20.5767 22.2957 16.7365 22.2957 11.9993C22.2957 7.26212 18.4555 3.42188 13.7183 3.42188C8.98111 3.42188 5.14087 7.26212 5.14087 11.9993C5.14087 16.7365 8.98111 20.5767 13.7183 20.5767Z"
                                                fill="#FFB300"/>
                                            <rect x="13" y="18" width="2" height="1" fill="#BF8600"/>
                                            <rect x="13" y="5" width="2" height="1" fill="#BF8600"/>
                                            <path
                                                d="M9 12.007C9 9.11405 11.2534 7 14.0298 7C15.1028 7 16.0686 7.27816 16.8733 7.82058V9.92072C16.1222 9.30876 15.1431 8.94715 14.0298 8.94715C12.3666 8.94715 11.0656 10.2545 11.0656 12.007C11.0656 13.7594 12.3666 15.0529 14.0298 15.0529C14.8346 15.0529 15.5455 14.7886 16.0686 14.3018V12.9944H13.614V11.1029H18V15.025C17.1416 16.2072 15.693 17 14.0298 17C11.2534 17 9 14.8999 9 12.007Z"
                                                fill="#BF8600"/>
                                        </svg>
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <svg
                        onClick={toggleMenu}
                        className='nav_burger' xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none">
                        <path d="M3 18V16H21V18H3ZM3 13V11H21V13H3ZM3 8V6H21V8H3Z" fill="#fff"/>
                    </svg>
                </div>
            </div>
        </header>
    );
};

export default Header;